/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HubService } from './abstract.hub.service';
import { ReplaySubject, Subject } from 'rxjs';
import { B2BDecisionParameter, BioPharmaDecisionKey, BioPharmaDecisionParameter, Decision, DecisionCore, DecisionDiscriminator } from '../generated/model/decision';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionNotificationService extends HubService {
  protected override console = LogService.initialize('DecisionNotificationService');
  private _decision$: Subject<Decision>;

  constructor(configService: ConfigService) {
    super();
    this.console.log(`constructor()`);
    const { decisionBaseUrl } = configService.config;
    this._baseUrl = decisionBaseUrl;
    this._decision$ = new ReplaySubject<Decision>(1);
  }

  public get decision$(): Subject<Decision> {
    return this._decision$;
  }

  public async sendDecision(decision: Decision): Promise<boolean> {
    this.console.log(`sendDecision ${this.getString(decision.parameter as DecisionCore)}`, decision);

    if (this.hubConnection) {
      // try {
      //   await this.hubConnection.invoke('Message', JSON.stringify(decision));
      // } catch (error) {
      //   console.error(error);
      //   window.alert(error);
      // }

      // if (decision.parameter) {
      //   decision.parameter.bioPharma = { key: BioPharmaDecisionKey.StrategyPricing };
      //   decision.when = undefined;
      //   decision.whenClientTime = undefined;
      // }

      try {
        await this.hubConnection.invoke('Decision', decision);
        return Promise.resolve(true);
      } catch (error) {
        console.error('Decision:', error);
        window.alert(error); // TODO@NST
      }
    }

    return Promise.resolve(false);
  }
  protected getUrl(teamId: string): string {
    return `${this.baseUrl}/decisionhub?instanceId=${encodeURIComponent(teamId)}`;
  }

  protected onHubConnectionBuilt(): void {
    if (this.hubConnection) {
      const event: string = 'DecisionReceived';
      this.hubConnection.on(event, message => {
        this.console.log('hubConnection.on', event, message);
        this.decision$.next(message);
      });
    }
  }

  private getString(parameter: DecisionCore): string {
    let text = parameter.discriminator === DecisionDiscriminator.B2B ?
      this.getStringB2B(parameter.b2B as B2BDecisionParameter) :
      this.getStringBio(parameter.bioPharma as BioPharmaDecisionParameter);

    if (parameter?.stringValue) {
      text += ` ${parameter?.stringValue}`;
    }

    if (parameter?.boolValue !== undefined) {
      text += ` ${parameter?.boolValue}`;
    }

    if (parameter?.intValue !== undefined) {
      text += ` ${parameter?.intValue}`;
    }

    return text;
  }

  private getStringB2B(parameter: B2BDecisionParameter): string {
    let text = `${parameter.key}`;

    if (parameter.market) {
      text += ` ${parameter.market}`;
    }

    if (parameter.productId) {
      text += ` ${parameter.productId}`;
    }

    if (parameter.projectId) {
      text += ` ${parameter.projectId}`;
    }

    return text;
  }

  private getStringBio(parameter: BioPharmaDecisionParameter): string {
    let text = `${parameter.key}`;

    if (parameter.disease) {
      text += ` ${parameter.disease}`;
    }

    return text;
  }
}
